import React from 'react'
import Layout from '../components/layout'
import SlideShow from '../components/SlideShow'
import Footer from '../components/footer';
import Header from '../components/header/index';

import red from '../images/bg01.jpg'
import blue from '../images/bg02.jpg'
import berlin02 from '../images/bg04.jpg'
import berlin03 from '../images/bg05.jpg'
import berlin04 from '../images/bg06.jpg'
import '../css/styles.css'

class IndexPage extends React.Component {

  componentDidMount() {
    document.body.classList.add("is-preload")
    window.addEventListener('load', this.initialLoadAnimation);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.initialLoadAnimation);
  }

  initialLoadAnimation = () => {
    window.setTimeout(function() {
      document.body.classList.remove("is-preload")
    }, 100);
  }

  render() {

    return (
      <Layout>
          <Header />

          <Footer />
         
          <SlideShow
            slideInterval={3400}
            autoplay
            slides={[ berlin03, red, berlin02, berlin04]}
          />
      </Layout>
    )
  }
}

export default IndexPage
