import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';

const HeaderTitle = styled.h1`
  font-family: 'wild_youthregular';
  padding-bottom: 0;
  margin-bottom: 0 !important;
`;
const HeaderSubTitle = styled.h3`
  font-family: 'Courier New', Courier, monospace;  
  font-weight: 200;
`;

const HeaderParagraph = styled.p`  
  font-weight: 200;
  font-family: 'Roboto';
`;

const Header = ({ siteTitle }) => (
  <header id="header">
    <HeaderTitle>Omar Gallo</HeaderTitle>
    <HeaderSubTitle><span className="fa fa-map-pin"></span> Berlin</HeaderSubTitle>
    <HeaderParagraph>Web Engineer based in Berlin.</HeaderParagraph>
  </header>
)

export default Header
