import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer id="footer">
    <ul className="icons">
      <li><a href="https://twitter.com/omargallob" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
      <li><a href="https://instagram.com/omargallob" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
      <li><a href="https://github.com/omargallob" className="icon fa-github"><span className="label">GitHub</span></a></li>              
    </ul>
    <ul className="copyright">
      <li>Built with <span className="fa fa-heart"></span> by &copy; Omar Gallo.</li>
    </ul>
  </footer>
)

export default Footer;
